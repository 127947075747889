import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomeUK from './pages/HomeUK';
import HomeUS from './pages/HomeUS';
import HomeAU from './pages/HomeAU';
import HomeArne from './pages/HomeArne';
import DevApp from './pages/DevApp';
import HomeBT from './pages/HomeBT';
import ProductsUK from './pages/ProductsUK';
import ProductsUS from './pages/ProductsUS';
import ProductsAU from './pages/ProductsAU';
import ProductsDev from './pages/ProductsDev';
import ProductsBT from './pages/ProductsBT';
import ProductsArne from './pages/ProductsArne';
import 'react-loading-skeleton/dist/skeleton.css'
import './App.scss';
import './index.css'
import SearchComponent from "./NostoSearchComponent";
import { loadWebbridge, WebbridgeProvider } from "@tapcart/webbridge-react";

const webbridgeClient = loadWebbridge({ test: false });
const App = () => {
  const router = createBrowserRouter([
    {
      path: "/app/TMDHuBwCVJ", //oh polly UK prod
      element: (
        <HomeUK />
      ),
    },
    {
      path: "/app/VaYDCjMYwX", //oh polly US prod
      element: (
        <HomeUS />
      ),
    },
    {
      path: "/app/juuHBVsmjZ", //oh polly AU prod
      element: (
        <HomeAU />
      ),
    },
    {
      path: "/app/arne", //arne prod
      element: (
        <HomeArne />
      ),
    },
    {
      path: "/app/uH4fUOUpU3", //bo+tee prod
      element: (
        <HomeBT />
      ),
    },
    {
      path: "/nosto",
      element: (
        <SearchComponent />
      ),
    },
    {
      path: "/app/0E4uVGGsmp", //oh polly dev app
      element: (
        <DevApp />
      ),
    },
    {
      path: "/products-bt/:query",
      element: <ProductsBT/>,
    },
    {
      path: "/products-uk/:query",
      element: <ProductsUK/>,
    },
    {
      path: "/products-au/:query",
      element: <ProductsAU/>,
    },
    {
      path: "/products-us/:query",
      element: <ProductsUS/>,
    },
    {
      path: "/products-dev/:query",
      element: <ProductsDev/>,
    },
    {
      path: "/products-arne/:query",
      element: <ProductsArne/>,
    },
  ]);
  return (
  <WebbridgeProvider webbridgeClient={webbridgeClient}>
  <RouterProvider router={router} />
  </WebbridgeProvider>
  );
};

export default App;