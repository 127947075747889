import React, { useEffect, useState, useRef } from "react";
import ProductImage from "./NostoProductImage.js";
import SearchPage from "./NostoSearchPage.js";
import { useActions, useVariables } from "@tapcart/webbridge-react";

const SearchComponent = () => {
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [showSearchPage, setShowSearchPage] = useState(false);
  const inputRef = useRef();
  const variables = useVariables();
  const {
    addToCart,
    openProduct,
    openCollection,
    applyDiscount,
    removeDiscounts,
    showToast
  } = useActions();

  const defaultData = {
    trending: [
        "homecomming dress",
        "white dress",
        "black dress",
        "party dress",
        "tapcart dress"
    ],
    collections: [

    ]
}

  useEffect(() => {
    inputRef?.current?.focus();
    fetch("https://search.nosto.com/v1/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query:
          "query ( $accountId: String, $query: String, $segments: [String!], $rules: [String!], $products: InputSearchProducts, $keywords: InputSearchKeywords, $sessionParams: InputSearchQuery ) { search( accountId: $accountId query: $query segments: $segments rules: $rules products: $products keywords: $keywords sessionParams: $sessionParams ) { query redirect products { hits { productId url name imageUrl thumbUrl description brand variantId availability price priceText categoryIds categories customFields { key value } priceCurrencyCode datePublished listPrice unitPricingBaseMeasure unitPricingUnit unitPricingMeasure googleCategory gtin ageGroup gender condition alternateImageUrls ratingValue reviewCount inventoryLevel skus { id name price listPrice priceText url imageUrl inventoryLevel customFields { key value } availability } pid onDiscount extra { key value } saleable available tags1 tags2 tags3 } total size from collapse fuzzy categoryId categoryPath } } }",
        variables: {
          accountId: "shopify-31139627146",
          // accountId: "shopify-31139627146",
          query: query ? query : "s",
          products: {
            size: 16 // TODO: Research how to return all results for infinity scroll on next page
          },
          sessionParams: {
            segments: [
              "613aa0000000000000000002",
              "613aa0000000000000000004",
              "61c26a800000000000000002",
              "5b71f1500000000000000006"
            ],
            products: {
              personalizationBoost: []
            }
          }
        }
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setProducts(response.data.search.products.hits);
      });
  }, [query]);

  useEffect(() => {
    fetch("https://search.nosto.com/v1/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `query {
          search(
            accountId: "shopify-31139627146"
            query: "${query || ""}"
            products: { size: 5 },
            keywords: { size: 5 }
          ) {
            products {
              hits {
                productId
                name
                imageUrl
              }
              total
            }
            keywords {
              hits {
                keyword
                _redirect
                _highlight {
                  keyword
                }
              }
            }
            query
          }
        }`,
        variables: {
          accountId: "shopify-31139627146",
          // accountId: "shopify-53103526077",
          query: query ? query : "shirt",
          products: {
            size: 16 // TODO: Research how to return all results for infinity scroll on next page
          },
          sessionParams: {
            segments: [
              "613aa0000000000000000002",
              "613aa0000000000000000004",
              "61c26a800000000000000002",
              "5b71f1500000000000000006"
            ],
            products: {
              personalizationBoost: []
            }
          }
        }
      })
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.data && response?.data?.search?.keywords?.hits.length != 0) setKeywords(response.data.search.keywords.hits)
        else {
          setKeywords([
            {
              keyword: 'Shirt'
            },
            {
              keyword: 'Faux'
            },
            {
              keyword: 'Jackets'
            },            
          ]);
        }
      });
  }, [query]);


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setShowSearchPage(true);
      // showToast({message: "Hello World", type: "error"});
    }
  };

  const openProductAction = (product) => {
    openProduct({ productId: product.pid.toString() });
  };

  const openCollectionAction = (collection) => {
    console.log(collection)
    openCollection({ collectionId: "174359904394" });
  };

  const handleTrendingProducts = (keyword) => {
    setProducts([])
    setQuery(keyword)
    setShowSearchPage(true)
  }

  return (
    <>
      {showSearchPage ? (
        <SearchPage
          products={products}
          goback={() => {
          setQuery('')
          setShowSearchPage(false)}}
        />
      ) : (
        <div className="search-container">

          <div className="site-header__search-input-wrapper">
            <div className="App-cont">
              <input
                  ref={inputRef}
                  className="site-header__search-input"
                  placeholder={"Search products"}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyPress={handleKeyPress}
              />
            </div>
          </div>


          {/*TODO: make App-cont this into its own component so we can reuse */}
          <div className="App-cont">
          <div>
            <p className="products-header">Trending Searches</p>
            <div className="search-tags">
              {keywords.map((obj) => {
                return <button onClick={()=>handleTrendingProducts(obj.keyword)} type="button">{obj.keyword}</button>
})}
            </div>
          </div>
          </div>

          <div className="App-cont">
            <p className="products-header">Collections</p>
            <div className="search-tags">
              <a href="/">Dress</a>
              <a href="/">Women&aposs Top</a>
            </div>
          </div>

          <div className="App-cont">
            <p className="products-header">Products</p>
            {
              products.map((product) => (
                  <a
                      className="product-card"
                      key={product.productId}
                      onClick={ () => openProductAction(product)}
                  >
                    <ProductImage
                        src={product.imageUrl}
                        alt={product.name}
                    />

                    <div className="card-details">
                      <p className="product-title">{product.name}</p>
                      <p className="product-price">
                        ${product.price.toFixed(2)}
                      </p>
                    </div>

                  </a>
              ))
            }
          </div>

        </div>
      )}
    </>
  );
};

export default SearchComponent;

//  {/* <div className="product-card" key={product.productId} onClick={() => Tapcart.actions.openProduct({ productId: product.productId, isRelatedProduct: false })}>
//         <img className="product-image" src={product.imageUrl} alt={product.name} />
//         <h4 className="product-title">{product.name}</h4>
//         <p className="product-price">${product.price}</p>
//       </div> */}
