import React from "react";

const SearchSuggestions = () => {
  return (
    // <div style={{minHeight:'100px'}}>
    <div></div>
  );
};

export default SearchSuggestions;
