import React from "react";
import { Link } from "react-router-dom";

const PopularSearches = ({ dev }) => {
  const linksData = [
    "embellished",
    "black dress",
    "homecoming dress",
    "white dress",
    "pink dress",
    "dress",
    "red dress",
    "embellished dress",
    "mini dress",
    "pink",
  ];
  return (
    <div>
      <h1 class="popular-searches">Popular Searches</h1>
      <div id="autocomplete"></div>
      <ul className="popular-list">
        {linksData.map((obj) => (
          <li className="popular-list-item">
            <Link
              className="popular-list-item-link"
              to={`/products${dev}/${obj}`}
            >
              <div>{obj}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularSearches;
