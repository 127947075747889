import React from "react";
import PopularSearches from "./PopularSearchesArne";
import SearchSuggestions from "./SearchSuggestions";

const PanelTopSection = ({ query, setQuery,dev }) => {
  return (
    <div className="panel-section-top">
      <SearchSuggestions query={query} />
    </div>
  );
};

export default PanelTopSection;
