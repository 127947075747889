import React, { Suspense, lazy, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
const PopularCollectionsItem = lazy(() => import("./PopularCollectionsItem"));

const PopularCollections = () => {
  const [finalCollectionData, setFinalCollectionData] = useState([]);
  const fetchAllCollections = async (arr) => {
    const STOREFRONT_ACCESS_TOKEN = "d8be8ec45740f77f2959ef7a2fe3f412";
    const shopStore = "oh-polly-us";
    const GRAPHQL_URL = `https://${shopStore}.myshopify.com/api/2023-01/graphql.json`;

    const promises = arr.map((handle) => {
      console.log("handles", handle)
      const query = `query {
                collectionByHandle(handle:"${handle.handle}") {
                id,
                title,
                handle
              }
            }`;

      const GRAPHQL_BODY = {
        method: "POST",
        headers: {
          "X-Shopify-Storefront-Access-Token": STOREFRONT_ACCESS_TOKEN,
          "Content-Type": "application/graphql",
        },
        body: query,
      };

      return fetch(GRAPHQL_URL, GRAPHQL_BODY);
    });

    const responses = await Promise.all(promises);
    const data = await Promise.all(responses.map((res) => res.json()));

    const handleResponse = data.map((item) => item.data.collectionByHandle);
    
    let finalCollectionArr = arr.map((item) => ({
      ...item,
      id: handleResponse.find((i) => i?.handle === item?.handle)?.id?.split("/")[4],
    }));
    console.log("final", finalCollectionArr)
    
    setFinalCollectionData(finalCollectionArr.filter(item => item.id !== undefined));
  };
  const shopifyProductFetch = async () => {
    const STOREFRONT_ACCESS_TOKEN = "d8be8ec45740f77f2959ef7a2fe3f412";
    const shopStore = "oh-polly-us";
    const GRAPHQL_URL = `https://${shopStore}.myshopify.com/api/2023-01/graphql.json`;

    const shopQuery = () => `query {
        shop {
          metafields(
            identifiers: [
             { namespace: "globals", key: "color_image"}
            { namespace: "algolia_collection", key: "title"}
              { namespace: "algolia_collection", key: "strapline"}
              { namespace: "algolia_collection", key: "image"}
              { namespace: "algolia_collection", key: "link"}
              { namespace: "algolia_collection", key: "cta"}
            ]
          ) {
            namespace
            value
          }
        }
      }
      `;

    const GRAPHQL_BODY = () => {
      return {
        async: true,
        crossDomain: true,
        method: "POST",
        headers: {
          "X-Shopify-Storefront-Access-Token": STOREFRONT_ACCESS_TOKEN,
          "Content-Type": "application/graphql",
        },
        body: shopQuery(),
      };
    };

    const res = await fetch(GRAPHQL_URL, GRAPHQL_BODY());
    const data = await res.json();
    console.log("aaa", data)
    let collectionsArray = data.data?.shop?.metafields?.map((item) =>
      JSON.parse(item.value)
    );
    // console.log("array", collectionsArray.map((i, index) => ({
    //     title: collectionsArray[1][index],
    //     handle: collectionsArray[4][index].split("/")[2],
    //     cta: collectionsArray[5][index],
    // })))
    const colArrayObj = collectionsArray.map((i, index) => ({
      title: collectionsArray[1][index],
      handle: collectionsArray[4][index].split("/")[2],
      cta: collectionsArray[5][index],
      imageUrl: collectionsArray[3][index][0].original_src,
    }));

    //console.log("test", colArrayObj)

    //setCollectionData(colArrayObj)
    // console.log("handle array", JSON.parse(data.data.shop.metafields[4].value || "[]").map(handle => handle.split("/")[2]))
    // console.log("title array", JSON.parse(data.data.shop.metafields[1].value))
    // console.log("cta array", JSON.parse(data.data.shop.metafields[5].value))
    fetchAllCollections(colArrayObj);
    //map(handles => handles.handle
  };
  useEffect(() => {
    shopifyProductFetch();
  }, []);
  // shopifyProductFetch();
  return (
    <div className="popular-collections">
      <h1>Popular Collections</h1>

      <div className="popular-collections-container">
        {finalCollectionData.length > 0 ? (
          finalCollectionData.map((item) => (
            <Suspense
              fallback={
                <div style={{ minWidth: "150px" }} className="popular-collection">
                  <Skeleton width={"100%"} height={212} />
                  <Skeleton
                    width={"100%"}
                    height={"56px"}
                    style={{ marginBottom: "5px" }}
                  />
                </div>
              }
            >
              <PopularCollectionsItem key={item.id} item={item} />
            </Suspense>
          ))
        ) : (
          <>
            <div style={{ minWidth: "150px" }} className="popular-collection">
              <Skeleton width={"100%"} height={212} />
              <Skeleton
                width={"100%"}
                height={"56px"}
                style={{ marginBottom: "5px" }}
              />
            </div>
            <div style={{ minWidth: "150px" }} className="popular-collection">
              <Skeleton width={"100%"} height={212} />
              <Skeleton
                width={"100%"}
                height={"56px"}
                style={{ marginBottom: "5px" }}
              />
            </div>
            <div style={{ minWidth: "150px" }} className="popular-collection">
              <Skeleton width={"100%"} height={212} />
              <Skeleton
                width={"100%"}
                height={"56px"}
                style={{ marginBottom: "5px" }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PopularCollections;
