import React, { useState } from "react";
import SearchResultPanel from "../components/SearchResultPanelUK";
import AutoCompWrapper from "../components/AutoCompWrapperUK";
import PopularCollections from '../components/PopularCollectionss'

const Home = () => {
  const [query, setQuery] = useState("");
  return (
    <>
      <AutoCompWrapper setQuery={setQuery} dev={"-uk"} />
      <div className={`main-panel`}>
        <SearchResultPanel query={query} setQuery={setQuery} dev={"-uk"} />
        <PopularCollections />
      </div>
    </>
  );
};


export default Home;
