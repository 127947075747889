import React, { useState } from "react";
import SearchResultPanel from "../components/SearchResultsPanelBT";
import AutoCompWrapper from "../components/AutoCompWrapperBT";
import PopularCollections from '../components/PopularCollectionsBT'

const Home = () => {
    const [query, setQuery] = useState("");
    return (
      <>
        <AutoCompWrapper setQuery={setQuery} dev={"-bt"} />
        <div className={`main-panel`}>
          <SearchResultPanel query={query} setQuery={setQuery} dev={"-bt"} />
          {/* <PopularCollections /> */}
        </div>
      </>
    );
  };
  
  
  export default Home;
  
