import React, { memo } from "react";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import Autocomplete from "./AutoCom";
import { ProductItem } from "./ProductItem";

const appId = "SVZ96CVK8C";
const apiKey = "05e902cabbd961adadc701c974144a61";
const searchClient = algoliasearch(appId, apiKey);
const AutoCompWrapper = memo(({ setQuery,dev }) => {
  return (
    <div className="app-container">
      <Autocomplete
        openOnFocus={false}
        setQuery={setQuery}
        dev={dev}
        getSources={({ query }) => [
          {
            sourceId: "products",

            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "shopify_uk_products_query_suggestions",
                    query,
                  },
                ],
              });
            },

            templates: {
              item({ item, components }) {
                return <ProductItem hit={item} components={components} dev={dev}  />;
              },
            },
          },
        ]}
      />
    </div>
  );
});

export default AutoCompWrapper;
