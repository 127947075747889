import React from "react";
import { Link } from "react-router-dom";

const PopularSearches = ({ dev }) => {
  const linksData = [
    "hoodie",
    "leggings",
    "jumper",
    "shorts",
    "unitard"
  ];
  return (
    <div>
      <div className="source-header">Popular Searches</div>
      <div id="autocomplete"></div>
      <ul className="popular-list">
        {linksData.map((obj) => (
          <li className="popular-list-item">
            <Link
              className="popular-list-item-link"
              to={`/products${dev}/${obj}`}
            >
              <div>{obj}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularSearches;
